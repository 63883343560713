import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import {
  faClock,
  faEnvelope,
  faMapPin,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import BOOKING_LINK from '../../../js/BOOKING_LINK'

const Footer = () => {
  return (
    <footer className="position-relative">
      <div className="container-fluid full-width-background-image-container">
        <div className="row h-100">
          <div className="col-md-6 background-image page-footer-background-flowers"></div>
          <div className="col-md-6"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <section className="col-md-6">
            <div className="spacer-background-image"></div>
          </section>
          <section className="col-md-6 p-5">
            <div>
              <div className="right-footer-header">
                <h2>Little Hair Lounge</h2>
              </div>

              <div className="footer-body py-3">
                <p>
                  <a
                    className="footer-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.google.com/maps/place/Little+Hair+Lounge/@-27.9393729,153.4008604,21z/data=!4m5!3m4!1s0x0:0x8d334d2658bc7a0a!8m2!3d-27.9395238!4d153.4008431?hl=en"
                  >
                    <FontAwesomeIcon className="footer-icon" icon={faMapPin} />
                    24 Ferguson Avenue, Labrador QLD 4215
                  </a>
                </p>
                <p>
                  <a className="footer-link" href="tel:0493242852">
                    <FontAwesomeIcon className="footer-icon" icon={faPhone} />
                    0493 242 852
                  </a>
                </p>
                <p>
                  <a
                    className="footer-link"
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:info@littlehairlounge.com.au"
                  >
                    <FontAwesomeIcon
                      className="footer-icon"
                      icon={faEnvelope}
                    />
                    info@littlehairlounge.com.au
                  </a>
                </p>
                <p>
                  <FontAwesomeIcon className="footer-icon" icon={faClock} />
                  By Appointment Only
                </p>
                <a
                  href={BOOKING_LINK}
                  rel="noreferrer"
                  target="_blank"
                  className="links"
                >
                  Book Now
                </a>
                <div className="social-links-container">
                  <a
                    href="https://www.facebook.com/littlehairlounge.LHL"
                    rel="noreferrer"
                    target="_blank"
                    aria-label="facebook link"
                    className="social-link"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>

                  <a
                    href="https://www.instagram.com/little.hair.lounge/"
                    rel="noreferrer"
                    target="_blank"
                    aria-label="instagram link"
                    className="social-link"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </footer>
  )
}

export default Footer
